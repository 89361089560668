import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"
import classnames from "classnames"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import PageHeader from "../../../components/pageheader"
import DevSingle from "../../../components/devsingle"
import { devPageLinks } from "../../../utils/constants"
import * as styles from "../../../styles/developments.module.css"
import hero from "../../../images/nashville-hero.jpg"
import miami from "../../../images/dev-prev-miami.jpg"
import bellwood from "../../../images/dev-next-bellwood.jpg"
import thumb from "../../../images/dev-thumb-gallery.jpg"
import big from "../../../images/dev-big-gallery.jpg"
import securityImg from "../../../images/dev-f-security.png"
import swimmingImg from "../../../images/dev-f-swimmingpool.png"
import parkImg from "../../../images/dev-f-park.png"
import joggingImg from "../../../images/dev-f-jogging.png"
import autoGateImg from "../../../images/dev-f-autogate.png"
import fitnessImg from "../../../images/dev-f-fitness.png"
import storesImg from "../../../images/dev-f-stores.png"
import bicycleImg from "../../../images/dev-f-bicycle.png"
import pamImg from "../../../images/dev-f-pam.png"
import floorPlanImg from "../../../images/dev-floorplan.jpg"

const types = [100, 129, 137, 166, 220]
const slides = new Array(15).fill(1)

const DevelopmentsTypeSinglePage = () => {
  const slideRef = useRef()
  const thumbRef = useRef()
  const typeRef = useRef()
  const [activeType, setActiveType] = useState(100)
  const [isTypeBarVisible, setIsTypeBarVisible] = useState(false)
  const goToPrev = () => {
    if (slideRef) slideRef.current.slickPrev()
  }
  const goToNext = () => {
    if (slideRef) slideRef.current.slickNext()
  }
  const goToThumbPrev = () => {
    if (thumbRef) thumbRef.current.slickPrev()
  }
  const goToThumbNext = () => {
    if (thumbRef) thumbRef.current.slickNext()
  }
  const thumbSettings = {
    arrows: false,
    fots: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    focusOnSelect: true,
    variableWith: true,
    asNavFor: slideRef.current,
  }
  const bigSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: thumbRef.current,
  }
  const typeClass = classnames({
    [styles.typeBarOpened]: isTypeBarVisible,
  })
  const toggleTypeBar = () => setIsTypeBarVisible(!isTypeBarVisible)
  useEffect(() => {
    setIsTypeBarVisible(false)
  }, [activeType])
  useEffect(() => {
    const checkIfClickOutside = e => {
      if (
        isTypeBarVisible &&
        typeRef.current &&
        !typeRef.current.contains(e.target)
      ) {
        setIsTypeBarVisible(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside)
    }
  }, [isTypeBarVisible])
  return (
    <Layout isTestimonialsHidden activeMenuId={3}>
      <Seo title="Nashville" />
      <PageHeader
        title="Nashville"
        subTitle={`The future of Tropical Urban Living. Tropical modern aesthetic is on showcase at its finest at Nashville, a little piece of heaven where tropical dreams come true. Every residential unit flaunts a sense of spaciousness with stunning floor-to-ceiling windows to allow the inhabitants to "live in the light" as sunlight is in abundance and to enjoy flexiblie living space that can be adjusted to different lifestyles.`}
        image={hero}
        breadcrumbs={[
          { label: "Home", link: "/" },
          { label: "Developments / Residential", link: "/developments/type" },
          { label: "Nashville", link: null },
        ]}
      />
      <DevSingle
        title="Nashville"
        mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.374155651361!2d106.96324531536634!3d-6.345570163850703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699361d93e77b1%3A0xb791970fbe2dae25!2sCluster%20Nashville%20Kota%20Wisata%20Cibubur!5e0!3m2!1sen!2sid!4v1638513551605!5m2!1sen!2sid"
        activePage="Types"
        pageLinks={devPageLinks}
        prev={{
          link: "/developments/type/single",
          label: "Miami",
          bgImage: miami,
        }}
        next={{
          link: "/developments/type/single",
          label: "Bellwood",
          bgImage: bellwood,
        }}
      >
        <div
          className={`relative mb-12 lg:mb-0 ${styles.typeBarWrap}`}
          ref={typeRef}
        >
          <button
            className={`relative py-4 mx-auto text-white block lg:hidden ${styles.typeToggle}`}
            onClick={toggleTypeBar}
          >{`Type ${activeType}`}</button>
          <ul
            className={`absolute lg:static justify-start items-center mb-16 px-4 ${styles.typeBar} ${typeClass}`}
          >
            {types.map(type => {
              const typeClass = classnames({
                [styles.activeType]: type === activeType,
              })
              return (
                <li key={type}>
                  <button
                    className={typeClass}
                    onClick={() => setActiveType(type)}
                  >{`Type ${type}`}</button>
                </li>
              )
            })}
          </ul>
        </div>
        <h2 className="mx-4 font-serif text-4xl mb-8">Gallery</h2>
        <div className="mb-8 developments-single-slider">
          <div className="slides-thumbnail hidden lg:block relative">
            <Slider ref={thumbRef} {...thumbSettings}>
              {slides.map((_, i) => {
                return (
                  <div key={`thumb-${i}`}>
                    <img src={thumb} alt="Thumbnail" />
                  </div>
                )
              })}
            </Slider>
            <button
              className={`hidden lg:block ${styles.slideNav} ${styles.slidePrev}`}
              onClick={goToThumbPrev}
            >
              Prev
            </button>
            <button
              className={`hidden lg:block ${styles.slideNav} ${styles.slideNext}`}
              onClick={goToThumbNext}
            >
              Next
            </button>
          </div>
          <div className="slides-main relative">
            <Slider ref={slideRef} {...bigSettings}>
              {slides.map((_, i) => {
                return (
                  <div key={`main-${i}`}>
                    <div className="text-center">
                      <img className="mx-auto" src={big} alt="Big Image" />
                    </div>
                  </div>
                )
              })}
            </Slider>
            <button
              className={`block lg:hidden ${styles.slideNav} ${styles.slidePrev}`}
              onClick={goToPrev}
            >
              Prev
            </button>
            <button
              className={`block lg:hidden ${styles.slideNav} ${styles.slideNext}`}
              onClick={goToNext}
            >
              Next
            </button>
          </div>
        </div>
        <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">Facilities</h2>
        <div className="mb-8 mx-4 lg:mx-0">
          <div
            className={`grid grid-cols-2 lg:grid-cols-5 gap-0 ${styles.facilitiesWrap}`}
          >
            <div>
              <div>
                <img
                  height={50}
                  src={securityImg}
                  alt="24/7 Security"
                />
              </div>
              24/7 Security
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={swimmingImg}
                  alt="Swimming Pool"
                />
              </div>
              Swimmming Pool
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={parkImg}
                  alt="Park"
                />
              </div>
              Park
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={joggingImg}
                  alt="Jogging Track"
                />
              </div>
              Jogging Track
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={autoGateImg}
                  alt="Autogate"
                />
              </div>
              Autogate
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={fitnessImg}
                  alt="Fitness Center"
                />
              </div>
              Fitness Center
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={storesImg}
                  alt="Stores"
                />
              </div>
              Stores
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={bicycleImg}
                  alt="Bicycle Track"
                />
              </div>
              Bicycle Track
            </div>
            <div>
              <div>
                <img
                  height={50}
                  src={pamImg}
                  alt="PAM"
                />
              </div>
              PAM
            </div>
          </div>
        </div>
        <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">Floor Plan</h2>
        <div className="mb-8 mx-4 lg:mx-0">
          <img
            src={floorPlanImg}
            alt="Floorplan"
          />
        </div>
        <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">Specification</h2>
        <div className="mx-4 lg:mx-0 mb-8">
          <p>
            Nashville - Type 220
            <br />
            Pondasi : Tiang Pancang
            <br />
            Lantai Utama : Homogenous Tile
            <br />
            Dinding : Batu Bata - plester & aci
            <br />
            Finishing Cat Dinding Jotun/Mowilex
            <br />
            Kusen : Aluminium Ex. YKK atau Setara
            <br />
            Daun Pintu Utama : Solid Panel/ Fullcore Engineering Door
            <br />
            Daun Pintu Dalam : Honeycomb Engineering Door & Alumunium
            <br />
            Plafon : Gypsum
            <br />
            Atap : Rangka Baja Ringan
            <br />
            Penutup Atap : Genteng Beton
            <br />
            Sanitair : Body Keramik Ex. American Standard atau Setara
            <br />
            Air : Standar PDAM
            <br />
            Listrik : 2200 - 3500 – 4400 VA
            <br />
            Carport : Kombinasi Granit Sikat & Keramik
          </p>
        </div>
      </DevSingle>
    </Layout>
  )
}

export default DevelopmentsTypeSinglePage
